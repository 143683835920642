import React from "react"
import Page from '../components/Page'
import Layout from "../components/Layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import Contactus from "../components/Contactus"

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblokState(pageContext.story, location)
  let extra_content = <Contactus title_1={story?.content?.footer_content_title} title_2 ={story?.content?.contact_form_title}/>
  return (
    <Layout location={location} full_slug={story?.full_slug} lang={story?.lang} content={story?.content} enableGetinTouch={story?.content?.get_in_touch_section}>
      <Page blok={story.content} location={location} extra_content={extra_content} />
    </Layout>
  )
}