import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext } from 'react'
import { Footer } from '../../../.cache/fast-refresh-overlay/components/overlay'
import DataContext from '../../context/dataContext'
import ContactPopup from '../ContactPopup'
import CustomLink from '../CustomLink'
import Form from '../Form'
import { isJsonParsable } from '../meta'
import "./contactus.scss"
import { cleanPath } from '../../../.cache/find-path';
import { getContent } from '../../utils/helper'

const Contactus = ({ title_1, title_2 }) => {
  const { lang } = useContext(DataContext)
  const { main_nav, sub_nav, footer } = useStaticQuery(graphql`
  query ContactPageFooter {
    footer: allStoryblokEntry(filter: { slug: { eq: "footer" } }) {
      edges {
        node {
          content
          lang
          slug
          full_slug
          uuid
          name
        }
      }
    }
    main_nav: allStoryblokEntry(
      filter: { field_component: { eq: "NavItems" } }
    ) {
      edges {
        node {
          id
          uuid
          content
          full_slug
          slug
          lang
        }
      }
    }
    sub_nav: allStoryblokEntry(
      filter: { field_component: { eq: "NavSubItem" } }
    ) {
      edges {
        node {
          id
          uuid
          content
          full_slug
          slug
          lang
        }
      }
    }
    news_items: allStoryblokEntry(
      filter: { field_component: { eq: "Post" }}
      sort: {fields: field_Published_Date_string, order: DESC}
      ) {
      edges {
        node {
          id
          uuid
          content
          full_slug
          slug
          lang
        }
      }
    }
  }
`);
  const Footer = footer?.edges.find(i => i?.node?.lang === lang);
  const footerData = isJsonParsable(Footer?.node?.content) ? JSON.parse(Footer?.node?.content) : null;
  const allNavData = main_nav?.edges?.filter(i => i?.node?.lang === lang).map((item) => {
    const node = item?.node
    const Node_Content = isJsonParsable(item?.node?.content) ? item?.node?.lang === lang && JSON.parse(item?.node?.content) : null
    const subNavItem = Node_Content?.sub_items?.map((i) => sub_nav?.edges?.filter(ite => ite?.node?.lang === lang)?.find((sub_i) => (sub_i?.node?.uuid === i && item?.node?.lang === lang)));
    return { node, Node_Content, subNavItem }
  })
  const Contact = allNavData?.filter(nav_item => (nav_item?.node?.slug === 'contacts'))
  const getContact = (Contact) => {
    return (
      <>

        {Array.isArray(footerData?.Footer_Items) &&
          footerData?.Footer_Items.map((items) => (
            <>
              <div className="footerBox">
                <h4 className="footerBoxTitle" >{items?.Title}</h4>
                <ul className="footerList add"><li> {getContent(items?.Description)} </li></ul>
                <ul className="footerList">
                  {Array.isArray(items?.Link) &&
                    items?.Link.map((link_item) => (<li> <CustomLink {...link_item?.url}> {link_item?.label} </CustomLink> </li>))}
                </ul>
              </div>
            </>
          ))}
      </>
    )
  }
  return (
    <div className='contactusPage cmnSecSpace'>
      <div className='container'>
        <div className='contactusContent'>
          <section className='contactLeft'>
            <h2>{title_1}</h2>
            <div className='contactList'>
              {getContact(Contact)}
            </div>
          </section>
          <section className='contactForm'>
            <Form lang={lang} title={title_2} />
          </section>
        </div>
      </div>
    </div>
  )
}

export default Contactus