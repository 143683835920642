import React, { useContext, useEffect, useRef, useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import './form.scss'
import { graphql, Link, useStaticQuery } from 'gatsby';
import { parse } from '../../utils/helper';
import Button from '../Button';
import Image from '../Image';
import { patterns, test, Validation } from '../../utils/validation';
import { Api } from '../../api/genrated/services';
import DataContext from '../../context/dataContext';
import { useMutation } from 'react-query';
import { Toast } from 'primereact/toast';

const Form = ({ lang , title}) => {
    const toast = useRef(null)
    const { curPath }= useContext(DataContext)
    const { data } = useStaticQuery(graphql`
    query ContactusFormQuery {
      data : allStoryblokEntry(filter: {field_component: {eq: "popup"}}) {
        edges {
          node {
            id
            content
            internalId
            lang
            full_slug
          }
        }
      }
    }

  `)
    const node = data?.edges?.find(i => i?.node?.lang === lang)?.node || null
    const content = parse(node?.content)
    const {
        sub_title,
        link, label,
        images,
        first_name,
        first_name_error,
        last_name,
        company_name,
        email,
        email_error,
        phone_number,
        phone_number_error,
        free_text,
        submit_btn,
        communication_channel_email,
        communication_channel_phone,
        communication_channel_title,
        toast_success,
        toast_error
    } = content || {}
    const { data : submit_res, mutate : submit, isLoading} = useMutation('contact-form',
    async ()=>{
        const res = await Api.submitForm({ requestBody: formData })
        return res
    },{
        onSuccess : (res)=>{
            setFormData(initial_data)
            toast.current?.show({
                closable: true, 
                severity: 'success',
                content: toast_success,
                life : 10000,
                sticky : true
            })
        },
        onError : (error)=>{
            toast.current?.show({
                closable: true, 
                severity: 'error',
                content: toast_error,
                life : 10000
            })
        }
    })
    const initial_data = {
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        phone_number: '',
        free_text: '',
        source_detail: curPath,
        communication_channel: ["phone"],
        result_text : null,
        result_code : 0
    }
    const [formData, setFormData] = useState(initial_data)
    const [errors, setErrors] = useState([])
    const submitHandler = (e) => {
        e.preventDefault();
        if (checkValidation()) {
            submit(formData)
        }
    }
    const changeHandler = (e) => {
        const field = e.target.id
        if (field?.startsWith("communication_channel")) {
            var updated_form = formData
            var array = updated_form?.communication_channel
            if (e.target.checked && !array?.includes(e.target.value)) {
                array?.push(e.target.value)
            } else if (!e.target.checked && array?.includes(e.target.value)) {
                array = array?.filter(i => i !== e.target.value)
                updated_form = { ...updated_form, communication_channel: array }
            }
            setFormData(updated_form)
            return
        }
        if (Object.keys(formData).includes(field)) {
            let updated_form = { ...formData, [field]: e.target.value }
            setFormData(updated_form)
        }
    }

    const checkValidation = () => {
        let new_errors = []
        Object.keys(formData).forEach(input => {
            if (Object.keys(patterns).includes(input)) {
                if (!Validation.isValidInput(input, formData[input])) {
                    new_errors.push(input)
                }
            }
        });
        setErrors(new_errors)
        return new_errors.length == 0
    }
    
    return (
            <div>
                <Toast ref={toast} position="top-left"/>
                <div className="popupWrap" >
                    <div className="popupLeft">
                        <h2>{title || ''}</h2>
                    </div>
                    <div className="popupRight">
                        <form className='formWrap' onSubmit={submitHandler}>
                            <div className={`inputWrap ${errors.includes('first_name') ? 'err' : ''}`}>
                                <input type="text" className='inputText' placeholder={first_name || ''} value={formData.first_name} onChange={changeHandler} id="first_name" />
                                {/* <span class="floating-label">{first_name || ''}</span> */}
                                {errors.includes('first_name') && <div className='errorMsg'>{first_name_error || ''}</div>}
                            </div>
                            <div className={`inputWrap ${errors.includes('last_name') ? 'err' : ''}`}>
                                <input type="text" className='inputText' placeholder={last_name || ''} value={formData.last_name} onChange={changeHandler} id="last_name" />
                                {/* <span class="floating-label">{last_name || ''}</span> */}
                            </div>
                            <div className={`inputWrap ${errors.includes('company_name') ? 'err' : ''}`}>
                                <input type="text" className='inputText' placeholder={company_name || ''} value={formData.company_name} onChange={changeHandler} id="company_name" />
                                {/* <span class="floating-label">{company_name || ''}</span> */}
                            </div>
                            <div className={`inputWrap ${errors.includes('email') ? 'err' : ''}`}>
                                <input type="text" className='inputText' placeholder={email || ''} value={formData.email} onChange={changeHandler} id="email" />
                                {/* <span class="floating-label">{email || ''}</span> */}
                                {errors.includes('email') && <div className='errorMsg'>{email_error || ''}</div>}
                            </div>
                            <div className={`inputWrap ${errors.includes('phone_number') ? 'err' : ''}`}>
                                <input type="text" className='inputText' placeholder={phone_number || ''} value={formData.phone_number} onChange={changeHandler} id="phone_number" />
                                {/* <span class="floating-label">{phone_number || ''}</span> */}
                                {errors.includes('phone_number') && <div className='errorMsg'>{phone_number_error || ''}</div>}
                            </div>
                            <div className="inputWrap radioInput">
                                <label className='radioLabel'>{communication_channel_title || ''}</label>
                                <div className='radioBoxWrap'>
                                    <div className='radioBox'>
                                        <input type="checkbox" value="email" onClick={changeHandler} id="communication_channel_email" />
                                        <label htmlFor="communication_channel_email">{communication_channel_email || ''}</label>
                                    </div>
                                    <div className='radioBox'>
                                        <input type="checkbox" defaultChecked onClick={changeHandler} value="phone" id="communication_channel_phone" />
                                        <label htmlFor="communication_channel_phone">{communication_channel_phone || ''}</label>
                                    </div>
                                </div>

                            </div>
                            <div className="inputWrap textarea">
                                <textarea name="question" className='inputText' placeholder={free_text || ''} value={formData.free_text} onChange={changeHandler} id="free_text"></textarea>
                                {/* <span class="floating-label">{free_text || ''}</span> */}
                            </div>
                            <div className="submitBtn">
                                <button type="submit" className='themeBtn withArrow'> {submit_btn || ''}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
    )
}

export default Form